<template>
	<div>
		<div class="row">
			<div class="col-7">
				<e-select
					id="status_selected"
					v-model="status_selected"
                    track-by="code"
					label="label"
					:placeholder="$t('monte.congelation.selectionnez_statut')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_status"
					:multiple="true"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-3">
				<e-select
					id="type_date"
					v-model="type_date"
                    track-by="code"
					label="label"
					:placeholder="$t('monte.congelation.selectionnez_type_date')"
					:selectedLabel="$t('global.selected_label')"
					:options="all_type_date"
				>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col-2">
				<b-button variant="primary" block @click="load_expeditions">{{ $t('global.rechercher') }}</b-button>
			</div>
			<div class="col-12">
	            <DateRangeV2
            		v-if="type_date"
	                :start.sync="start_date"
	                :end.sync="end_date"
	                :dateSelect="true"
	                :periodToShow="['personalise','jour','mois','mois_dernier']"
	                :submitButton="false"
	            />
			</div>
		</div>

		<div v-if="expeditions.length == 500">
			<b-alert variant="warning" show>{{ $t('monte.congelation.only_500_result') }}</b-alert>
		</div>

        <CustomTable
            id_table="semence_expedition"
            ref="table"
            :items="expeditions"
            :busy.sync="table_busy"
            primaryKey="semenceexpedition_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['semenceexpedition_qte','edit', 'semenceexpedition_commentaire', 'demandeur.tiers_tags', 'destinataire.tiers_tags']"
            :callback_one="commande_expedition_checkall"
            :callback_one_deselect="commande_expedition_decheckall"
            :columsAddBegin="['edit']"
        >
            <template v-slot:[`custom-slot-cell(semenceexpedition_qte)`]="{ data }">
            	{{ data.semenceexpedition_qte }}
            	<template v-if="data.semenceexpedition_dose">{{ $t('monte.doses') }}</template>
            	<template v-else>{{ $t('monte.paillettes') }}</template>
            </template>
            <template v-slot:[`custom-slot-cell(edit)`]="{ data }">
                <a href="" class="" @click.prevent="setUpEditInvoiceExpedition(data)">
                    <font-awesome-icon :icon="['far', 'money-bill-alt']" />
                </a>
            </template>
            <template v-slot:[`custom-slot-cell(semenceexpedition_commentaire)`]="{ data }">
            	<span v-html="data.semenceexpedition_commentaire"></span>
            </template>
			<template v-slot:[`custom-slot-cell(demandeur.tiers_tags)`]="{ data }">
				<span v-if="data.demandeur.tags.length > 0" class="tag-manage"> <span v-for="tag in data.demandeur.tags" :key="tag.tags_id" :style="'background-color:'+tag.tags_color" class="tags tags-table"></span> </span>
            </template>
            <template v-slot:[`custom-slot-cell(destinataire.tiers_tags)`]="{ data }">
				<span v-if="data.destinataire.tags.length > 0" class="tag-manage"> <span v-for="tag in data.destinataire.tags" :key="tag.tags_id" :style="'background-color:'+tag.tags_color" class="tags tags-table"></span> </span>
            </template>

        </CustomTable>


		<b-modal ref="modal-courrier" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.congelation.courrier_expedition") }}
				</template>
			</template>

			<div v-if="expeditions_courrier.length > 0" class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
						<e-select
							v-model="template"
							id="model_id"
							track-by="model_label"
							label="model_label"
							:placeholder="$t('horse.search_doc')"
							:deselectLabel="$t('global.press_enter_to_remove')"
							:selectedLabel="$t('global.selected_label')"
							:options="templates"
							:searchable="true"
							:allow-empty="false"
							:show-labels="false"
						/>
					</div>
				</div>
				<div class="col-12 text-center">
					<b-button class="mt-1 rounded-pill" variant="primary" @click="print">{{ $t("global.print") }} <font-awesome-icon v-if="processing_courrier" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"
    import Navigation from "@/mixins/Navigation.js"
	import Model from "@/mixins/Model.js"
    import { EventBus } from "EventBus"

	export default {
		name: 'ExpeditionList',
		mixins: [Congelation, ShutterCongelation, Navigation, Model],
		props: ['horse_id'],
		data () {
			return {
				table_busy: false,
				expeditions: [],
				config_table_hrefs: {
					'stallion.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'mare.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'mare.horse_id'
						}
					},
					'demandeur.tiers_rs': {
						routeUniqueName: 'demandeurFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'demandeur.tiers_id'
						}
					},
					'destinataire.tiers_rs': {
						routeUniqueName: 'destinataireFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					},
					'client.tiers_rs': {
						routeUniqueName: 'clientFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'client.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToConfirmExpedition': this.goToConfirmExpedition,
					'TableAction::goToPdfDemandeExpedition': (expeditions) => {
						this.open_courrier(expeditions, 'demande_enlevement')
					},
					'TableAction::goToPdfBonLivraison': (expeditions) => {
						this.open_courrier(expeditions, 'bon_livraison')
					},
					'TableAction::goToEditExpedition': this.setUpEditInvoiceExpedition
				},
				all_status: [{
					code: 5,
					label: this.getTrad('monte.congelation.status.waiting')
				},{
					code: 6,
					label: this.getTrad('monte.congelation.status.expedie')
				},{
					code: 7,
					label: this.getTrad('monte.congelation.status.livre')
				},{
					code: 8,
					label: this.getTrad('monte.congelation.status.facture')
				}],
				status_selected: [],
				all_type_date: [{
					code: 'semenceexpedition_enlevement_date',
					label: this.getTrad('monte.congelation.date.semenceexpedition_enlevement_date')
				}],
				type_date: null,
				start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
				end_date: new Date(),
				templates: [],
				template: null,
				processing_courrier: false,
				expeditions_courrier: [],
				courrier_type: ''
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.status_selected = this.all_status.filter(st => [5].includes(st.code))
				this.load_expeditions()
				this.all_transporteurs = await this.getAllTransporteurs()
			},

			async load_expeditions() {
				if(this.status_selected.length == 0) {
					return false
				}
				this.table_busy = true
				const expeditions = await this.getExpeditions(this.horse_id, this.status_selected.map(st => st.code), this.type_date, this.start_date, this.end_date)
				expeditions.forEach(exp => {
					exp.statut = this.getTrad('monte.congelation.status.'+exp.semenceexpedition_status)
				})
				this.expeditions = expeditions
				this.table_busy = false
				if(this.$refs.table) {
					this.$refs.table.unselectAll()
				}
			},

			async goToConfirmExpedition(expeditions) {
				await this.confirmExpeditions(expeditions.map(exp => exp.semenceexpedition_id).join(','))
				EventBus.$emit('TableAction::stopSpin', true)
				this.successToast('toast.info_save_succes')
				this.load_expeditions()
			},

            commande_expedition_checkall(item) {
                if(item.length > 0) {
					const destinataire_id = item[0].semenceexpedition_destinataire
					const cuve_id = item[0].semenceexpedition_cuve
					const same_expedition = this.expeditions.filter(exp => exp.semenceexpedition_destinataire == destinataire_id && exp.semenceexpedition_cuve == cuve_id)
					this.$refs.table.manual_check(same_expedition)
				}
            },

            commande_expedition_decheckall(item) {
                if(item.length > 0) {
					const destinataire_id = item[0].semenceexpedition_destinataire
					const cuve_id = item[0].semenceexpedition_cuve
					const same_expedition = this.expeditions.filter(exp => exp.semenceexpedition_destinataire == destinataire_id && exp.semenceexpedition_cuve == cuve_id)
					this.$refs.table.manual_decheck(same_expedition)
				}
			},

			async open_courrier(expeditions, type) {
				this.templates = await this.loadAllModel(0, [type])
				this.courrier_type = type
				this.expeditions_courrier = expeditions

				this.$refs['modal-courrier'].show()
			},

			async print() {
				if(!this.template) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}

				this.processing_courrier = true
				const ids = this.expeditions_courrier.map(exp => exp.semenceexpedition_id).join(',')
				await this.printExpeditionCourrier(ids, this.courrier_type, this.template.model_id, this.template.model_label)
				this.processing_courrier = false
				this.$refs['modal-courrier'].hide()
				this.template = null
				this.courrier_type = ''
				this.expeditions_courrier = null
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
		}
	}

</script>